<template>
  <div>
    <TableIndex
      :title="title"
      :resource-name="resourceName"
      :singular-name="singularName"
      :can-create="false"
      :toggle-status="false"
      :reload="reload"
    >
      <template #custom_actions="{ item, currentActions }">
        <b-button
          v-if="item.actions.can_change_status"
          variant="primary"
          size="sm"
          class="ml-1"
          style="min-width: 120px;padding: 0.5rem 5px;"
          @click.prevent="showStatusModal(item.id)"
        >
          Change Status
        </b-button>

        <b-link
            v-b-tooltip.hover.v-primary
            title="Details"
            class="ml-1"
            :to="{name:'contactus-details',params:{id:item.id}}"
        >
          <feather-icon
              icon="EyeIcon"
              size="16"
              class="align-middle text-body"
          />
        </b-link>
      </template>
    </TableIndex>
    <b-modal
      id="status-modal"
      ref="status-modal"
      no-close-on-backdrop
      ok-variant="warning"
      modal-class="modal-warning"
      centered
      title="Confirmation"
    >
      <b-card-text>
        Are you sure you want to Change this item Status?
        <hr>
        <validation-observer
          ref="form"
          v-slot="{invalid}"
        >
          <b-form
            novalidate
            @submit.prevent="confirmChangeStatus"
          >
            <validation-provider
              v-slot="{ errors }"
              vid="status"
              name="Status"
              rules="required"
            >
              <b-form-group label="Status">
                <v-select
                  v-model="form.status"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="value"
                  :options="statusList"
                  :reduce="item => item.id"
                  placeholder="Status"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-form>
        </validation-observer>
      </b-card-text>
      <template #modal-footer="{ cancel }">
        <b-button @click="cancel()">
          Cancel
        </b-button>
        <b-button
          variant="warning"
          @click="confirmChangeStatus"
        >
          Confirm
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import listMixin from '@/mixins/listMixin'

export default {
  mixins: [listMixin],
  data() {
    return {
      formErrors: null,
      title: 'Contactus',
      resourceName: 'contact-us',
      singularName: 'Contactus',
      statusList: [],
      selectedItem: null,
      reload: 0,
      form: {
        status: null,
      },
    }
  },
  methods: {
    showDetails(id) {
      console.log(id)
    },
    // eslint-disable-next-line func-names
    inputChange: _.debounce(function (e, field) {
      this.searchFields[field] = e
    }, 500),
    showStatusModal(item) {
      this.selectedItem = item
      this.$refs['status-modal'].show()
      this.form.status = null
    },
    loadStatuses() {
      this.axios.get('/contact-us/constants')
        .then(res => {
          this.statusList = res.data.data.status
        })
    },
    confirmChangeStatus() {
      const item = this.selectedItem
      this.$refs.form.validate()
        .then(result => {
          if (result) {
            this.axios
              .patch(`/contact-us/${item}/status`, this.form)
              .then(res => {
                this.$refs['status-modal'].hide()
                this.selectedItem = null
                this.$bvToast.toast(res.data.message, {
                  title: 'Success',
                  variant: 'success',
                  solid: true,
                  autoHideDelay: 3000,
                  appendToast: true,
                })
                this.reload += 1
              })
          } else {
            this.formErrors = this.$refs.form.errors
          }
        })
    },
  },
  created() {
    this.loadStatuses()
  },
}
</script>
